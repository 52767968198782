import React, { useEffect } from "react"

import { getFullUrl as apiGetFullUrl } from "../services/tenantsApiV2"

import LoadingIcon from "../components/loadingIcon/LoadingIcon"

// Redirect based on an api call
const BnchUrlShortenedRedirect = ({ location }) => {
  console.log("location", location)

  useEffect(() => {
    const redirectUser = async () => {
      const token = location.pathname.replace("/bnch/", "")

      try {
        const data = await apiGetFullUrl(token)
        if (data.value) {
          window.location.href = data.value
        }
      } catch (e) {
        console.log(e)
      }
    }

    redirectUser()
  }, [location])

  return (
    <div className="loadingWrapper">
      <LoadingIcon />
    </div>
  )
}

export default BnchUrlShortenedRedirect
