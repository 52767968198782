import React from "react"
import "./LoadingIcon.css"

import LoadingImage from "./icon.png"

function LoadingIcon() {
  return <img alt="Loading" id="loading-icon" src={LoadingImage} />
}

export default LoadingIcon
